
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































.home-organizing-text,
[class*='home-organizing-text--'] {
  @include default-margin;

  position: relative;
  overflow: hidden;
}

.home-organizing-text-wrapper {
  @include mq(m) {
    display: flex;
  }
}

.home-organizing-text__title {
  margin-bottom: $spacing * 1.25;
  line-height: 1;

  @include mq(m) {
    width: col(3, 12);
    margin: 0 col(1, 12) 0 0;
  }
}

.home-organizing-text__text {
  @include mq(m) {
    width: col(5, 12);
  }
}

.home-organizing-text__picture {
  img {
    width: 100%;
  }

  @include mq(m) {
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 0;

    img {
      @include image-fit(contain, right);
    }
  }
}
