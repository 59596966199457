
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































































































































.home-organizing-hero {
  overflow: hidden;

  &.before-onboard {
    opacity: 0;
  }
}

.home-organizing-header {
  @extend %text-center;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 25vh 0;

  @include mq(l) {
    height: 65vh;
    padding: 0;
  }
}

.home-organizing-header__subtitle {
  ::v-deep em {
    color: $c-mustard;
  }
}

.home-organizing-header__intro {
  max-width: 50rem;
  margin: 0 auto;
}

.home-organizing-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing * 0.5;

  @include mq(l) {
    gap: $spacing * 2.5 $spacing * 3;
    margin-top: 5vh;
  }
}

.home-organizing-gallery__title {
  flex-basis: 100%;
  margin: $spacing * 0.7 0;
  font-style: normal;

  ::v-deep em {
    color: $c-mustard;
  }

  @include mq(l) {
    flex: 25% 1 1;
    align-self: flex-end;
  }
}

.home-organizing-gallery__text {
  flex-basis: 100%;
  margin-bottom: $spacing * 2.5;
  line-height: 3rem;

  @include mq(l) {
    flex: 25% 1 1;
    order: 10;
  }
}

.home-organizing-gallery__picture {
  position: relative;
  flex: 45% 1 1;
  order: -1;
  overflow: hidden;
  height: 0;
  padding-bottom: 50%;
  border-radius: 2rem;

  img {
    @include image-fit;
  }

  &:nth-last-child(1),
  &:nth-last-child(2) {
    order: 0;
  }

  @include mq(l) {
    flex: 25% 1 1;
    order: 0;
    // padding-bottom: 32%; // DESIGN
    padding-bottom: 30%;

    &:nth-child(3) {
      order: -1;
    }
  }
}
