
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































































































































.home-organizing-organizer {
  position: relative;
  padding-top: 80%;

  @include mq(m) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    padding: $spacing * 5 0;

    li:nth-child(2n) & {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.home-organizing-organizer__name {
  @include mq(m) {
    margin-left: col(1, 12);

    li:nth-child(2n) & {
      margin: 0 col(1, 12) 0 0;
    }
  }
}

.home-organizing-organizer__title {
  font-size: 3rem;

  ::v-deep em {
    display: block;
    color: $c-dark-grey;
  }

  @include mq(m) {
    font-size: 8rem;
  }
}

.home-organizing-organizer__subtitle {
  @extend %fw-light;

  margin-top: $spacing * 0.25;

  @include mq(m) {
    margin-top: $spacing * 0.5;
  }
}

.home-organizing-organizer__picture {
  @include center-x;

  z-index: -1;
  top: 0;
  width: 100vw;

  img {
    width: 100%;
  }

  @include mq(m) {
    right: -10%;
    left: initial;
    width: 80%;
    transform: none;

    li:first-child & {
      top: -20%;
    }

    li:nth-child(2n) & {
      right: initial;
      left: -10%;
    }
  }
}

.home-organizing-organizer__episode {
  position: relative;
  margin-top: $spacing * 2;

  @include mq(m) {
    width: col(6, 12);
    margin-top: 0;
  }
}

.home-organizing-organizer__episode__link {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  width: calc(100% - #{$spacing});
  padding: $spacing;
  text-decoration: none;
  background: $c-white;
  border-radius: 0.8rem;
  transform: translate(-50%, 20%);

  .icon {
    display: block;
    margin-top: $spacing * 0.75;
    color: $c-mustard;
    transition: transform 0.5s $ease-softer;
  }

  &:hover,
  &:focus-visible {
    .icon {
      transform: translateX(1em);
    }
  }

  @include mq(m) {
    width: calc(100% - #{$spacing * 5});
    padding: $spacing * 2;
    font-size: 3rem;

    .icon {
      margin-top: $spacing;
    }
  }
}

.home-organizing-organizer__episode__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  border-radius: 2rem;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);

  ::v-deep .picture__container {
    position: static;
  }

  ::v-deep img {
    transition: transform 1s $ease-softer;
  }

  .home-organizing-organizer__episode__link:hover + &,
  .home-organizing-organizer__episode__link:focus-visible + & {
    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    ::v-deep img {
      transform: scale(1.1);
    }
  }

  @include mq(m) {
    padding-bottom: 100%;
  }
}
